<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">测温统计</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <div>
        <el-form-item label="单位" prop="parentId">
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.parentId"
            height="200"
          ></el-select-tree>&nbsp;
          <el-checkbox v-model="queryModel.subordinate">是否包含下级单位</el-checkbox>
        </el-form-item>
        <el-form-item label="统计时间" prop="timeRanges">
          <el-date-picker
            v-model="queryModel.timeRanges"
            size="mini"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            align="right"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="单位" prop="companyId">
          <el-select
            v-model="queryModel.companyId"
            size="mini"
            filterable
            placeholder="请选择"
            style="width:220px"
          >
            <el-option
              v-for="company in companyResult"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            ></el-option>
          </el-select>&nbsp;
          <el-checkbox v-model="queryModel.subordinate">是否包含下级单位</el-checkbox>
          <el-checkbox v-model="queryModel.distinct">是否去重</el-checkbox>
        </el-form-item>-->
        <!-- <el-form-item label="人员姓名" prop="personName">
          <el-input type="text" size="mini" v-model="queryModel.personName"></el-input>
        </el-form-item>-->
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
          >查询</el-button>&nbsp;
          <!-- <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
          >重置</el-button>&nbsp;-->
        </el-form-item>
      </div>
    </el-form>
    <el-divider></el-divider>
    <!-- <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="downloadXls"
      >导出数据</el-button>
    </el-row>-->
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      show-summary
      :summary-method="getSummaries"
      stripe
      :height="tableHeight"
      tooltip-effect="light"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="companyName" label="单位名称" width="220"></el-table-column>
      <el-table-column prop="personTotal" label="应测人数" width="100"></el-table-column>
      <el-table-column prop="checkPersonTotal" label="已测人数" width="100"></el-table-column>
      <el-table-column prop="checkPersonTotalOnDevice" label="设备测温人数" width="120"></el-table-column>
      <!-- <el-table-column label="体温上报人数" width="100">
        <template slot-scope="{row}">{{row.checkPersonTotal - row.checkPersonTotalOnDevice}}</template>
      </el-table-column> -->
      <el-table-column label="总漏测人数" width="100">
        <template slot-scope="{row}">{{row.personTotal - row.checkPersonTotal}}</template>
      </el-table-column>
      <el-table-column type="expand" width="40">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="漏测人员名单">
              <span v-if="props.row.unCheckPersonName != ''">{{ props.row.unCheckPersonName }}</span>
              <span v-else>无</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="unCheckPersonName" label="漏测名单" width="500" show-overflow-tooltip></el-table-column>
      <el-table-column prop="unCheckPersonNameOnDevice" label="只上报体温名单" width="500" show-overflow-tooltip></el-table-column>
    </el-table>
  </div>
</template>
<script>
import Constant from "@/constant";
import temperatureRecordApi from "@/api/base/temperatureRecord";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import pageUtil from "@/utils/page";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: 'BaseTemperatureRecordList',
  data() {
    var self = this;

    return {
      queryModel: {
        personName: "",
        timeRanges: "",
        parentId: "",
        subordinate: false,
        distinct: false
      },
      loading: false,
      tableData: [],
      field: "",
      direction: "",
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      downloadLoading: false,
      tableHeight: 0,
      timeRangesDefaultTime: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    this.loadTree();
    this.getCurrentMonthFirst();
  },
  methods: {
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      var times =
        self.queryModel.timeRanges +
        " 00:00:00," +
        self.queryModel.timeRanges +
        " 23:59:59";

      formData.append("timeRanges", times);
      formData.append("personName", self.queryModel.personName);

      if (self.queryModel.parentId == null) {
        self.queryModel.parentId = "";
      }
      formData.append("parentId", self.queryModel.parentId);
      formData.append("subordinate", self.queryModel.subordinate);
      formData.append("distinct", self.queryModel.distinct);

      formData.append(
        "temperatureRanges",
        self.queryModel.temperatureBegin + "," + self.queryModel.temperatureEnd
      );

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      temperatureRecordApi
        .getCompanyRecordReport(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data.list;

          self.tableData = jsonData;
          //45为分页栏的高度
          //页面高度-列表上面的高度-分页栏高度
          self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.queryModel.timeRanges = "";
      this.queryModel.temperatureEnd = "";
      this.queryModel.subordinate = false;
      this.queryModel.distinct = false;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    //初始化日期
    getCurrentMonthFirst() {
      var self = this;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      var data = date.getDate();
      if (data < 10) {
        data = "0" + data;
      }

      var startTime = year + "-" + month + "-" + data;

      self.queryModel.timeRanges = startTime;
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总人数";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += " 人";
        } else {
          sums[index] = "";
        }

        if (index >= 5) {
          sums[index] = "";
        }
      });

      return sums;
    }
  },
  mounted: function() {
    //this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>